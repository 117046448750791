import * as React from "react";
import history from "./history";
import Axios from "axios";

export default class LoginFinal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stylePath: this.props.cSS,
      userName: "/Ayman",
      id_user: 1,
      pass_user: "1111",
    };
  }

  componentDidMount() {
    this.props.setIsAuthAyman(false);
    this.props.setIsAuthMahmoud(false);
    this.props.setIsAuthHadary(false);
    this.props.setIsAuthDalia(false);
    this.props.setIsAuthSamy(false);
    this.props.setIsAuthIbrahim(false);
    this.props.setIsAuthMohamed(false);
    this.props.setIsAuthAssal(false);
  }

  handleButtonClick(style_Path) {
    this.setState({ stylePath: style_Path });
    this.props.setCSS(style_Path);
    localStorage.setItem("styleCSS", style_Path);
  }

  userChange = (event) => {
    var mm = event.target.value;
    if (mm == "Ayman Shawky") {
      this.setState({ userName: "/Ayman", id_user: 1 });
    } else if (mm == "Mahmoud AbdElHafiz") {
      this.setState({ userName: "/Mahmoud", id_user: 2 });
    } else if (mm == "Mahmoud ElHadary") {
      this.setState({ userName: "/Hadary", id_user: 3 });
    } else if (mm == "Dalia Ossman") {
      this.setState({ userName: "/Dalia", id_user: 4 });
    } else if (mm == "Samy Farag") {
      this.setState({ userName: "/Samy", id_user: 5 });
    } else if (mm == "Ibrahim Saaid") {
      this.setState({ userName: "/Ibrahim", id_user: 6 });
    } else if (mm == "Mohammed Ahmmed") {
      this.setState({ userName: "/Mohammed", id_user: 7 });
    } else if (mm == "Ahmed Assal") {
      this.setState({ userName: "/Assal", id_user: 8 });
    }
  };

  passChange = (event) => {
    var mm = event.target.value;
    this.setState({ pass_user: mm });
  };

  handelBtnSubmit = () => {
    console.log("Submit Click");

    const portGet =
      "http://localhost:3001/user/" + this.state.id_user ||
      "https://db.egyptianporcelain.com/user/" + this.state.id_user;

    var ch = false;

    Axios.get(portGet)
      .then((res) => {
        console.log("get sucess");
        console.log(res.data[0].pass);
        if (res.data[0].pass == this.state.pass_user) {
          ch = true;
        }
        if (ch) {
          console.log("Right Right pass");
          if (this.state.userName == "/Ayman") {
            this.props.setIsAuthAyman(true);
          } else if (this.state.userName == "/Mahmoud") {
            this.props.setIsAuthMahmoud(true);
          } else if (this.state.userName == "/Hadary") {
            this.props.setIsAuthHadary(true);
          } else if (this.state.userName == "/Dalia") {
            this.props.setIsAuthDalia(true);
          } else if (this.state.userName == "/Samy") {
            this.props.setIsAuthSamy(true);
          } else if (this.state.userName == "/Ibrahim") {
            this.props.setIsAuthIbrahim(true);
          } else if (this.state.userName == "/Mohammed") {
            this.props.setIsAuthMohamed(true);
          } else if (this.state.userName == "/Assal") {
            this.props.setIsAuthAssal(true);
          }
        } else {
          console.log("Wrong Wrong pass");
          this.props.setIsAuthAyman(false);
          this.props.setIsAuthMahmoud(false);
          this.props.setIsAuthHadary(false);
          this.props.setIsAuthDalia(false);
          this.props.setIsAuthSamy(false);
          this.props.setIsAuthIbrahim(false);
          this.props.setIsAuthMohamed(false);
          this.props.setIsAuthAssal(false);
        }
      })
      .then(() => {
        console.log(
          "Get Done................................" +
            this.state.userName +
            "   ID:  " +
            this.state.id_user
        );
        history.push(this.state.userName);
      });
  };

  render() {
    return (
      <div>
        <link rel="stylesheet" type="text/css" href={this.state.stylePath} />

        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <a className="navbar-brand">CNC</a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarColor02"
            aria-controls="navbarColor02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarColor02">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a className="nav-link">
                  Home
                  <span className="sr-only">(current)</span>
                </a>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Thems
                </a>
                <div className="dropdown-menu">
                  <a
                    className="dropdown-item"
                    role="button"
                    onClick={this.handleButtonClick.bind(
                      this,
                      "/Bootstrap/Cerulean.css"
                    )}
                  >
                    Cerulean
                  </a>
                  <a
                    className="dropdown-item"
                    role="button"
                    onClick={this.handleButtonClick.bind(
                      this,
                      "/Bootstrap/Darkly.css"
                    )}
                  >
                    Darkly
                  </a>
                  <a
                    className="dropdown-item"
                    role="button"
                    onClick={this.handleButtonClick.bind(
                      this,
                      "/Bootstrap/Flatly.css"
                    )}
                  >
                    Flatly
                  </a>
                  <a
                    className="dropdown-item"
                    role="button"
                    onClick={this.handleButtonClick.bind(
                      this,
                      "/Bootstrap/Journal.css"
                    )}
                  >
                    Journal
                  </a>
                  <a
                    className="dropdown-item"
                    role="button"
                    onClick={this.handleButtonClick.bind(
                      this,
                      "/Bootstrap/Litera.css"
                    )}
                  >
                    Litera
                  </a>
                  <a
                    className="dropdown-item"
                    role="button"
                    onClick={this.handleButtonClick.bind(
                      this,
                      "/Bootstrap/Pulse.css"
                    )}
                  >
                    Pluse
                  </a>
                  <a
                    className="dropdown-item"
                    role="button"
                    onClick={this.handleButtonClick.bind(
                      this,
                      "/Bootstrap/Sketchy.css"
                    )}
                  >
                    Sketchy
                  </a>
                  <a
                    className="dropdown-item"
                    role="button"
                    onClick={this.handleButtonClick.bind(
                      this,
                      "/Bootstrap/Slate.css"
                    )}
                  >
                    Slate
                  </a>
                  <a
                    className="dropdown-item"
                    role="button"
                    onClick={this.handleButtonClick.bind(
                      this,
                      "/Bootstrap/United.css"
                    )}
                  >
                    United
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>

        <div className="container p-3 my-3 bg-dark text-white">
          <h1>CNC Project</h1>
          <p>
            CNC Projects website to manage Project of EGPC-Mould & Tools
            Departement, Kindly login.
          </p>
        </div>

        <div className="container p-3 my-3 border">
          <form>
            <fieldset>
              <legend>Login</legend>

              <div className="form-group">
                <label htmlFor="exampleSelect1">Users</label>
                <select
                  className="form-control"
                  id="exampleSelect1"
                  onChange={this.userChange}
                >
                  <option>Ayman Shawky</option>
                  <option>Mahmoud AbdElHafiz</option>
                  <option>Mahmoud ElHadary</option>
                  <option>Dalia Ossman</option>
                  <option>Samy Farag</option>
                  <option>Ibrahim Saaid</option>
                  <option>Mohammed Ahmmed</option>
                  <option>Ahmed Assal</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  onChange={this.passChange}
                />
              </div>

              <button
                type="button"
                className="btn btn-primary"
                onClick={this.handelBtnSubmit}
              >
                Submit
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}
/*<Link
                className="btn btn-primary"
                to={this.state.userName}
                onClick={this.handelBtnSubmit}
              >
                Submit
              </Link>*/
