import { useState } from "react";
import { Router, Link, Route, Switch } from "react-router-dom";
//import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import history from "./history";
import ProtectedRoute from "./ProtectedRoute";
import Mahmoud from "./Pages/Mahmoud";
import Ayman from "./Pages/Ayman";
import Hadary from "./Pages/Hadary";
import Samy from "./Pages/Samy";
import Mohammed from "./Pages/Mohammed";
import Ibrahim from "./Pages/Ibrahim";
import Assal from "./Pages/Assal";
import Dalia from "./Pages/Dalia";
//import LoginPage from "./LoginPage";
import LoginFinal from "./LoginFinal";

function Pagess() {
  const [isAuthMah, setIsAuthMahmoud] = useState(false);
  const [isAuthAym, setIsAuthAyman] = useState(false);
  const [isAuthHad, setIsAuthHadary] = useState(false);
  const [isAuthSam, setIsAuthSamy] = useState(false);
  const [isAuthDal, setIsAuthDalia] = useState(false);
  const [isAuthAss, setIsAuthAssal] = useState(false);
  const [isAuthIbr, setIsAuthIbrahim] = useState(false);
  const [isAuthMoh, setIsAuthMohamed] = useState(false);
  const [cSS, setCSS] = useState(() => {
    if (localStorage.getItem("styleCSS") == null) {
      return "/Bootstrap/cerulean.css";
    } else {
      return localStorage.getItem("styleCSS");
    }
  });

  return (
    <Router history={history}>
      <Route path="/" exact>
        <LoginFinal
          setCSS={setCSS}
          cSS={cSS}
          setIsAuthMahmoud={setIsAuthMahmoud}
          setIsAuthAyman={setIsAuthAyman}
          setIsAuthHadary={setIsAuthHadary}
          setIsAuthSamy={setIsAuthSamy}
          setIsAuthDalia={setIsAuthDalia}
          setIsAuthAssal={setIsAuthAssal}
          setIsAuthIbrahim={setIsAuthIbrahim}
          setIsAuthMohamed={setIsAuthMohamed}
        />
      </Route>
      <ProtectedRoute
        path="/Mahmoud"
        component={Mahmoud}
        isAuth={isAuthMah}
        cSS={cSS}
      />
      <ProtectedRoute
        path="/Ayman"
        component={Ayman}
        isAuth={isAuthAym}
        cSS={cSS}
      />
      <ProtectedRoute
        path="/Hadary"
        component={Hadary}
        isAuth={isAuthHad}
        cSS={cSS}
      />
      <ProtectedRoute
        path="/Samy"
        component={Samy}
        isAuth={isAuthSam}
        cSS={cSS}
      />
      <ProtectedRoute
        path="/Mohammed"
        component={Mohammed}
        isAuth={isAuthMoh}
        cSS={cSS}
      />
      <ProtectedRoute
        path="/Ibrahim"
        component={Ibrahim}
        isAuth={isAuthIbr}
        cSS={cSS}
      />
      <ProtectedRoute
        path="/Assal"
        component={Assal}
        isAuth={isAuthAss}
        cSS={cSS}
      />
      <ProtectedRoute
        path="/Dalia"
        component={Dalia}
        isAuth={isAuthDal}
        cSS={cSS}
      />
    </Router>
  );
}

export default Pagess;
