import React from "react";
import { Route, Redirect } from "react-router-dom";

function ProtectedRoute({
  isAuth: isAuth,
  component: Component,
  cSS: cSS,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuth) {
          return <Component cc={cSS} />;
        } else {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
      }}
    ></Route>
  );
}

export default ProtectedRoute;
