import React from "react";
import { withRouter } from "react-router-dom";

const Mohammed = (props) => {
  return (
    <div>
      <link rel="stylesheet" type="text/css" href={props.cc} />
      <div className="container p-3 my-3 bg-dark text-white">
        <h1>Mohammed</h1>
        <p>If you see this you are Authenticated.</p>
      </div>
    </div>
  );
};
//<link rel="stylesheet" type="text/css" href={this.state.stylePath} />
// "/Bootstrap/sketchy.css"
export default withRouter(Mohammed);
